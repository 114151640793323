import "./src/styles/custom.css"
import "./src/styles/global.css"
import "./src/styles/loder.css"

// gatsby-browser.js
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const onClientEntry = () => {
  document.querySelector("body").style.overflow = "hidden"
  document.getElementById("page-loader-wrapper").style.display = "block"
  gsap.registerPlugin(ScrollTrigger)
}

export const onRouteUpdate = () => {
  document.querySelector(".page-loader-wrapper__inner").style.opacity = "0"
  setTimeout(() => {
    document.getElementById("page-loader-wrapper").style.display = "none"
    document.querySelector("body").style.overflow = "auto"
  }, 400)
}
